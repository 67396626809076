@primaryGreen: #00573d;
@menuColor: @primaryGreen;
@dividerColor: #ddd;
@activeColor: #337ab7;

.container {
    margin-bottom: 30px;
}

@media (min-width: 1400px) {
    .container {
        width: 1280px;
    }
}

hr {
    border-color: @dividerColor;
}

h2:not(.swal2-title),
.h2:not(.swal2-title) {
    border-bottom: 1px solid @dividerColor;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 26px;
}

.top-nav-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.logo {
    margin: 10px 0;
    width: 400px;
}

.navbar-toggle {
    padding: 7px 10px 2px 10px;
    margin-right: 0;
}
.nav > li > a:focus,
.nav > li > a:hover {
    background-color: lighten(@menuColor, 12%);
}
.nav a {
    color: #fff;
}
.navbar-custom {
    background-color: @menuColor;
    border-color: @menuColor;
    color: #fff;
}
.navbar-custom .navbar-brand {
    font-size: 28px;
}
.navbar-custom .nav > li > a {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 13px;
}
.title-restricted {
    font-size: 65%;
    position: relative;
    top: -8px;
    padding-left: 2px;
}

.form-control-feedback.fa {
    line-height: 34px;
}

.fa-xl {
    font-size: 1.333em;
}

.cart {
    position: relative;
    top: 2px;
}
.cart-total {
    position: absolute;
    top: 6px;
    right: 4px;
}

.table-small th,
.table-small td {
    font-size: 80%;
}
.table-xs th,
.table-xs td {
    font-size: 70%;
}

.performance-table table,
table.performance-table,
.leed-reports-table table,
table.leed-reports-table {
    width: 100%;
    margin-bottom: 0;
}
.performance-table td,
.performance-table th,
.leed-reports-table td,
.leed-reports-table th {
    text-align: center;
    vertical-align: middle !important;
    border: 1px solid #ddd !important;
    padding: 5px !important;
    font-size: 75%;
}
.performance-table th,
.leed-reports-table th {
    background-color: #eee;
}
.performance-table th i[class~="fa-sort-asc"] {
    display: block;
}
.performance-table th i[class~="fa-sort-desc"] {
    display: block;
    margin-top: -10px;
    margin-left: 0px !important;
}
.performance-table .table-striped > tbody > tr:nth-of-type(odd):not(:hover) {
    background-color: inherit;
}
.performance-table.saved-products-table,
.performance-table.saved-glazings-table {
    margin-bottom: 10px;
}

.performance-table.saved-products-table td:nth-child(1),
.performance-table.saved-products-table td:nth-child(2) {
    text-align: left;
    vertical-align: top !important;
}

.leed-reports-table.saved-products-table td:nth-child(1) {
    text-align: left;
    vertical-align: top !important;
}

.performance-table.saved-products-table td:nth-child(1) {
    width: 300px;
}
.performance-table.saved-products-table td:nth-child(2) {
    width: 300px;
}
.performance-table.saved-products-table td:nth-child(20) {
    white-space: nowrap;
}

.performance-table.saved-glazings-table td:nth-child(1) {
    text-align: left;
    vertical-align: top !important;
}
.performance-table.saved-glazings-table td:nth-child(1) {
    width: 300px;
}
.performance-table.saved-glazings-table td:nth-child(13) {
    white-space: nowrap;
}
.performance-table.saved-glazings-table td:nth-child(13) button {
    font-size: 11px;
}

.color-swatch {
    width: 40px;
    height: 40px;
    border: 1px solid black;
}
.color-swatch-sm {
    width: 20px;
    height: 20px;
    display: inline-block;
    border: 1px solid black;
    margin-bottom: -5px;
    margin-right: 6px;
}

.d-tc {
    display: table-cell;
}
.v-align-top {
    vertical-align: top !important;
}
.v-align-middle {
    vertical-align: middle !important;
}

.pagination {
    margin-top: 0;
    font-size: 75%;
}
.pagination > li > a,
.pagination > li > span {
    padding: 3px 8px;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
    background-color: @primaryGreen;
    border-color: @primaryGreen;
}

.framing-system-table tr.selected td {
    background-color: @activeColor;
    color: #fff;
}

/* "No data to display" message */
entity-list > div > h3 {
    margin-top: 5px;
    font-size: 18px;
}

.ib-container.v-align-middle > * {
    vertical-align: middle;
}
.ib-container > * {
    display: inline-block !important;
}
.ib-container.p-sm > *:not(:first-child) {
    margin-left: 5px;
}

// accordion-group > .panel > .panel-heading {
//     cursor: pointer;
// }

.ngx-pagination {
    padding-left: 0;
}

.btn-primary,
.btn-primary.focus,
.btn-primary:focus,
.btn-primary.active,
.btn-primary:active,
.open > .dropdown-toggle.btn-primary,
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus,
.open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
    color: #fff;
    background-color: @primaryGreen;
    border-color: #00310b;
}
.btn-primary:hover,
.btn-primary.active:hover,
.btn-primary:active:hover {
    background-color: darken(@primaryGreen, 2%);
}
.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
    background-color: lighten(@primaryGreen, 5%);
}

/* Custom Sizing */
@customSizeBorderWidth: 3px;
.total-product-grid {
    box-sizing: border-box;
}

.total-product-grid input::-webkit-outer-spin-button,
.total-product-grid input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
.total-product-grid input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
}

.total-product-grid thead th {
    font-weight: normal;
    vertical-align: middle;
}

.total-product-grid .product-row {
    border-collapse: collapse;
    width: 100%;
}

.total-product-grid .product-row > .frame-cell {
    border: @customSizeBorderWidth solid #333;
    height: 100%;
}

.total-product-grid .product-row > .frame-cell {
    position: relative;
}

.total-product-grid .frame-row-header {
    width: 65px;
    text-align: center;
    font-size: 85%;
    padding-left: 5px;
    padding-right: 5px;
}
.total-product-grid .frame-row-header > a {
    cursor: pointer;
}
.total-product-grid .frame-row-header input {
    text-align: center;
    padding: 0;
    width: 100%;
}
.total-product-grid .frame-col-header {
    text-align: center;
    padding: 8px 0px;
    font-size: 85%;
}
.total-product-grid .frame-col-header > a {
    cursor: pointer;
}
.total-product-grid .frame-col-header input {
    text-align: center;
    padding: 0;
}

.total-product-grid .vertical-header {
    position: relative;
    vertical-align: middle;
    text-align: center;
}
.total-product-grid .vertical-header > div {
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #fff;
}

.total-product-grid .vertical-header:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 1px dashed black;
    transform: translate(-50%);
}

.total-product-grid .horizontal-header {
    text-align: center;
}
.total-product-grid .horizontal-header > div {
    border-bottom: 1px dashed black;
    line-height: 0.001em;
    width: 100%;
    text-align: center;
    margin: 14px 0px;
}
.total-product-grid .horizontal-header > div > span {
    background: #fff;
    padding: 0 10px;
}

// slider customization
.ngx-slider-pointer {
    background-color: @primaryGreen !important;
    top: -9px !important;
    width: 20px !important;
    height: 20px !important;
    border-radius: 10px !important;
}
.ngx-slider-pointer:after {
    width: 6px !important;
    height: 6px !important;
    top: 7px !important;
    left: 7px !important;
}
.ngx-slider-pointer.ngx-slider-active:after {
    background-color: @primaryGreen !important;
}
.ngx-slider-selection {
    background-color: @primaryGreen !important;
}

.ngx-slider[disabled] .ngx-slider-selection,
.ngx-slider[disabled] .ngx-slider-pointer.ngx-slider-active:after,
.ngx-slider[disabled] .ngx-slider-pointer {
    background-color: rgb(189, 189, 189) !important;
}

.product-performance-header {
    font-size: 16px;
    font-weight: bold;
    color: #00573d !important;
}

// button margins
@btnMarginAmt: 5px;
.btn-margin-bottom {
    margin-bottom: @btnMarginAmt;
}

// recommendations table
.first-header-green th:first-child,
.last-header-green th:last-child {
    background-color: @primaryGreen;
    color: white;
}

.builder-actions {
    margin-bottom: 50px;
}

/* Multiselect Styling */
.dropdown-list {
    position: absolute !important;
    padding-top: 14px !important;
    width: 320px !important;
    z-index: 99999;
    top: 38px !important;
    left: 0px !important;
}

div.arrow-down,
div.arrow-up {
    display: none !important;
}

.selected-list span {
    font-size: 14px !important;
}

.project-modal .swal2-popup {
    min-width: 500px !important;
}

/* LEED Report Sytlings */
.btn-path-choice {
    padding: 10px 20px;
    font-size: 28px;
}

/* Custom Product Inquiry Form Stylings */
#custom-product-inquiry-help-btn {
    position: fixed;
    bottom: 19px;
    padding: 10px 15px;
    right: 44px;
    font-size: 15px;
}

#custom-product-inquiry-form {
    background: white;
    border: 1px solid #0000001f;
    padding: 20px;
    position: fixed;
    width: 330px;
    bottom: 19px;
    right: 44px;
    box-shadow: 5px 10px #88888830;
}
